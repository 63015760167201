import React from "react";
import { Link } from "react-router-dom";

import styles from './Calculator.module.css'
import arrow from '../../img/arrow.svg'
import { useRef } from "react";
import CalculatorCopart from "./CalculatorCopart";
import CalculatorCopartGBP from "./CalculatorCopartGBP";
import CalculatorSynetiq from "./CalculatorSynetiq";

const Calculator = () => {


    const UKRef = useRef();
    const EURef = useRef();
    const SynRef = useRef();
    const nameRef = useRef()
    let index = 100

    

    

    let SwichCalcRight = () => {
        index++
        
        
        if (index%3 == 2) {
            EURef.current.style.display = "flex"
            SynRef.current.style.display = "none"
            UKRef.current.style.display = "none"
            nameRef.current.textContent = "Copart Америка"
        }

        if  (index%3 == 0){
            UKRef.current.style.display = "flex"
            EURef.current.style.display = "none"
            SynRef.current.style.display = "none" 
            nameRef.current.textContent = "Copart Англия"
        }

        if (index%3 == 1){
            EURef.current.style.display = "none"
            SynRef.current.style.display = "flex"
            UKRef.current.style.display = "none"
            nameRef.current.textContent = "SYNETIQ"
        }

        
        
    }

    let SwichCalcLeft = () => {
        index -= 1
        
        
        if (index%3 == 2) {
            EURef.current.style.display = "flex"
            SynRef.current.style.display = "none"
            UKRef.current.style.display = "none"
            nameRef.current.textContent = "Copart Америка"
        }

        if  (index%3 == 0){
            UKRef.current.style.display = "flex"
            EURef.current.style.display = "none"
            SynRef.current.style.display = "none" 
            nameRef.current.textContent = "Copart Англия"
        }

        if (index%3 == 1){
            EURef.current.style.display = "none"
            SynRef.current.style.display = "flex"
            UKRef.current.style.display = "none"
            nameRef.current.textContent = "SYNETIQ"
        }

        
        
    }

    return (
        <>
        <h4>КАЛЬКУЛЯТОР СТОИМОСТИ МАШИНОКОМПЛЕКТА</h4>
            <div className={styles.switch}>
                <img src={arrow} onClick={SwichCalcLeft}/>
                <h4 ref={nameRef}>SINETIQ</h4>
                <img src={arrow} className={styles.mirror} onClick={SwichCalcRight}/>
            </div>

            <div ref={SynRef} className={styles.netuSyn}>
                <CalculatorSynetiq/>
            </div>

            <div ref={EURef} className={styles.netuEU}>
                <CalculatorCopart/>
            </div>

            <div ref={UKRef} className={styles.netuUK}>
               <CalculatorCopartGBP/>
            </div>
        </>
    );
}

export default Calculator;