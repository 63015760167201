import React, { useRef } from "react";
import styles from "./CalculatorCopart.module.css"
import arrow from "../../img/arrow.svg"
import { Route, Routes, Link } from "react-router-dom";

import CalculatorCopartGBP from "./CalculatorCopartGBP";
import CalculatorSynetiq from "./CalculatorSynetiq";

const CalculatorCopart = () => {

    let rate = 0

    const getRate = () => {
        fetch('https://api.apilayer.com/fixer/latest?base=EUR&apikey=xAznTr5HZxU80XsGhCGew02qbSb075IJ&symbols=RUB')
        .then(data => {
            return data.json()
        })
        .then(data => {
            rate = (data.rates.RUB).toFixed((2) )
        })
    }

    let price = 0;

    

    const loadFee = 40;
    
    const mainBlock = useRef()
    const stavkaRef = useRef()
    const sborRef = useRef()
    const itogRef = useRef()
    const kgstavkaRef = useRef()
    const uslugiRef = useRef()
    const massaRef = useRef()
    const tamojnyaRef = useRef()
    const rateRef = useRef()

    let sklad = 0;
    let carType = 0;
    let vat = 1;
    
    


    getRate()





    const ITOG = () => { 
        


        let stavka = Number(stavkaRef.current.value)
        let sbor = Number(sborRef.current.value)
        let kgstavka = Number(kgstavkaRef.current.value)
        let uslugi = Number(uslugiRef.current.value)
        let massa = Number(massaRef.current.value)
    

        price = kgstavka * massa + sbor + stavka + uslugi +  + sklad + carType

        
        
        let internetFee = 0;
        if (price >= 100 && price < 500) {
            internetFee = 25
        }
        if (price >= 500 && price < 1000) {
            internetFee = 39
        }
        if (price >= 1000 && price < 1500) {
            internetFee = 59
        }
        if (price >= 1500 && price < 2000) {
            internetFee = 69
        }
        if (price >= 2000 && price < 4000) {
            internetFee = 79
        }
        if (price >= 4000 && price < 6000) {
            internetFee = 89
        }
        if (price >= 6000 && price < 8000) {
            internetFee = 95
        }
        if (price >= 8000) {
            internetFee = 99
        }
        
        let priceAndFee = 0   
     
        if (vat == 1) {
            priceAndFee = price + loadFee + internetFee;
        }
        else {
            priceAndFee = price + loadFee + internetFee + (price * vat);
        }

        if (Number.isInteger(Math.floor(priceAndFee)) == true) {
            itogRef.current.textContent = priceAndFee + ' Евро / ' + Math.floor(priceAndFee * rate) + ' Рублей '
        }
        else
            itogRef.current.textContent = ' Поля заполнены неверно '

        rateRef.current.textContent = 'по курсу ' +  rate + ' рублей за €'
    }
    

    return (
        <div className={styles.main} >

            <Routes>
                <Route path="Synetiq" element={<CalculatorSynetiq/>}/>
                <Route path="CopartUK" element={<CalculatorCopartGBP/>}/>
            </Routes>

            

           <div className={styles.submain} ref={mainBlock}>
           <div className={styles.MainInputsArea}>
                <div>
                    <p>Ставка на торгах</p>
                    <div className={styles.InputWTag}>
                        <input type="text" placeholder="" ref={stavkaRef}/>
                        <div className={styles.tag}>€</div>
                    </div>
                </div>
                <div >
                    <p>Аукционные сборы</p>
                    <div className={styles.InputWTag}>
                        <input type="text" placeholder="" ref={sborRef}/>
                        <div className={styles.tag}>€</div>
                    </div>
                </div>
                <div className={styles.transparent}>
                    <p>Ставка за килограмм</p>
                    <div className={styles.InputWTag}>
                        <input type="text" placeholder="" />
                        <div className={styles.tag}></div>
                    </div>
                </div>
                <div>
                    <p>Услуги компании</p>
                    <div className={styles.InputWTag}>
                        <input type="text" placeholder="500" ref={uslugiRef} value={500}/>
                        <div className={styles.tag}>€</div>
                    </div>
                </div>
                <div>
                    <p>Масса машинокомплекта</p>
                    <div className={styles.InputWTag}>
                        <input type="text" placeholder=""ref={massaRef}/>
                        <div className={styles.tag}>КГ</div>
                    </div>
                </div>
                <div>
                    <p>Ставка за килограмм</p>
                    <div className={styles.InputWTag}>
                        <input type="text" placeholder="1.4" ref={kgstavkaRef} value={1.4}/>
                        <div className={styles.tag}>€</div>
                    </div>
                </div>
            </div>

            <div className={styles.rightBlock}>
            <div className={styles.nax_komplekt}>
                <label className={styles.container}>
                    <input type="radio" name="komplekt"/><p>Полный комплект</p>
                    <span className={styles.checkmark}></span>
                </label>                
                
                <label className={styles.container}>
                    <input type="radio" name="komplekt"/><p>Полукомплект</p>
                    <span className={styles.checkmark}></span>
                </label>                
                
                <label className={styles.container}>
                    <input type="radio" name="komplekt"/><p>Миникомплект</p>
                    <span className={styles.checkmark}></span>
                </label>                

                <label className={styles.container}>
                    <input type="radio" name="komplekt"/><p>Моторокомплект</p>
                    <span className={styles.checkmark}></span>
                </label>                
                
                                
                
            </div>

            <div className={styles.nax_typeAndDop}>

                <div className={styles.nax_dop}>
                    <label className={styles.container}>
                    <input type="radio" name="dop" onClick={() => {vat = 0.2}}/><p>Покупка с VAT (+ НДС 20%)</p>
                        <span className={styles.checkmark}></span>
                    </label>                

                    <label className={styles.container}>
                    <input type="radio" name="dop"/><p>Авто категории B</p>
                        <span className={styles.checkmark}></span>
                    </label> 
                </div>

                <div className={styles.nax_type}> 
                    <label className={styles.container}>
                        <input type="radio" name="type" onClick={() => {carType = 0}} /><p>Легковой автомобиль</p>
                        <span className={styles.checkmark}></span>
                    </label>                
                    
                    <label className={styles.container}>
                        <input type="radio" name="type" onClick={() => {carType = 100}} /><p>BUS, SUV(+100€)</p>
                        <span className={styles.checkmark}></span>
                    </label>                

                    <label className={styles.container}>
                        <input type="radio" name="type" onClick={() => {carType = 100}} /><p>Кроссовер, минивен(+100€)</p>
                        <span className={styles.checkmark}></span>
                    </label> 
                </div>

            </div>

            

            <div className={styles.ItogWTag}>
                <div className={styles.itog}>
                    <h2 onClick={ITOG} ref={itogRef}>
                        ИТОГОВАЯ СТОИМОСТЬ:
                    </h2>
                </div>
            </div>

            
            <p ref={rateRef} className={styles.rate}></p>

            </div>
            </div>

            

        </div>
    )

}

export default CalculatorCopart;