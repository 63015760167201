import React from "react";
import "./modal.css"

const Modal = ({active, setActive}) => {
    return(

        <div className={active ?  "modal active" : "modal"} onClick={() => setActive(false)}>
            <div className="modalContent" onClick={e => e.stopPropagation()}>
                    <h4>Спасибо за заявку</h4>
                    <p>Наши менеджеры скоро свяжутся с вами</p>
            </div>
        </div>

    )
} 

export default Modal;