import About from "./components/About/About";
import Calculator from "./components/Calculator/Calculator";
import Footer from "./components/Footer/Footer";
import MainPage from "./components/MainPage/MainPage";
import MyMap from "./components/Map/MyMap";
import MyForm from "./components/MyForm/MyForm";
import RazborkaPage from "./components/RazborkaPage/RazborkaPage";
import styles from "./styles/main.module.css"

function App() {
  return (
    <div className={styles.App}>
      <MainPage/>
      <RazborkaPage/>
      <Calculator/>
      <About/>
      <div id="svyaz">
        <MyForm/>
      </div>
      <MyMap/>
      <Footer/>
      

    </div>
  );
}

export default App;
