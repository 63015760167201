import React from "react";
import tg from "../../img/telegram.svg"
import vk from "../../img/image 4.svg"
import yt from "../../img/image 5.svg"
import ig from "../../img/image 6.svg"
import logo from "../../img/logo.svg"
import pbtn from "../../img/image 18.svg"
import geo from "../../img/geo.svg"
import styles from "./footer.module.css"

const Footer = () => {

    return (
        <div className={styles.main}>

            <div className={styles.pc}>
            <div className={styles.socials}>
            <a href="#"><img src={yt} alt="" /></a>
                <a href="https://vk.com/fastmparts"><img src={vk} alt="" /></a>
                <a href="#"><img src={ig} alt="" /> </a>
            </div>

            <div className={styles.mailNGeo}>
                <div className={styles.pox}>
                    <img src={pbtn} alt="" />
                    <a className={styles.mail} href="mailto:fastmotopa@gmail.com">fastmotopa@gmail.com</a>
                </div>
                <div className={styles.pox}>
                    <img src={geo} alt="" />
                    <p>220037, город Минск, улица Передовая 15, помещение 2, офис №6 УНП 193658755</p>
                </div>
            </div>

            <div className={styles.phones}>
                <a href="tel:+375296759394">+375296759394</a>
                <a href="tel:+375444651227">+375444651227</a>
                <a href="tel:+79534423671">+79534423671</a>
            </div>

            <div className={styles.tgNLogo}>
                <div className={styles.tg}>
                    <img src={tg} alt="" />
                    <a href="https://t.me/fastmotoparts">Все новости в нашем телеграмм канале</a>
                </div>

                <img src={logo} alt="" className={styles.logo}/>

            </div>
            </div>

            <div className={styles.phone}>
                <div className={styles.tg}>
                    <img src={tg} alt="" />
                    <a href="https://t.me/fastmotoparts">Все новости в нашем телеграмм канале</a>
                </div>

                <div className={styles.ce}>
                    <div className={styles.socials}>
                        <a href=""><img src={yt} alt="" /></a>
                        <a href="https://vk.com/fastmparts"><img src={vk} alt="" /></a>
                        <a href=""><img src={ig} alt="" /></a>
                    </div>
                    <div className={styles.mailNPhones}>
                        <a className={styles.mail} href="mailto:fastmotopa@gmail.com">fastmotopa@gmail.com</a>
                        <div className={styles.phones}>
                            <a href="tel:+375296759394">+375296759394</a>
                            <a href="tel:+375444651227">+375444651227</a>
                            <a href="tel:+79534423671">+79534423671</a>
                        </div>
                    </div>
                </div>
                <div className={styles.pox}>
                    <img src={geo} alt="" />
                    <p>220037, город Минск, улица Передовая 15, помещение 2, офис №6 УНП 193658755</p>
                </div>
            </div>
            

        </div>
    )

}

export default Footer