import React, { createRef, useRef } from "react";
import styles from "./Header.module.css"
import tg from "../../img/telegram.svg"
import vk from "../../img/image 4.svg"
import yt from "../../img/image 5.svg"
import ig from "../../img/image 6.svg"
import logo from "../../img/logo.svg"
import pbtn from "../../img/image 18.svg"


const MyHeader = () => {
    
    const btnRef = useRef()
    const headerRef = useRef()
    

    const openMenu = () => {
        if(btnRef.current.style.display === 'none') {
            btnRef.current.style.display = 'block';
            headerRef.current.style.backgroundColor = "RGBA(255, 255, 255, 0.4)"
          } else {
            btnRef.current.style.display = 'none'
            headerRef.current.style.backgroundColor = "transparent"
          }
        
    }
   
    
    return (
    <div className={styles.HeaderMain} ref = {headerRef}>
            <div className={styles.menuBtn} onClick={openMenu}>
                <img src={pbtn} alt="" />
            </div>
            
            <div className={styles.socialbar}>
                <div className={styles.omfg}>
                    <img src={tg} alt="" />
                    <a href="https://t.me/fastmotoparts" className={styles.social}>fastmotoparts</a>
                </div>
                <div className={styles.omfg}>
                    <img src={vk} alt="" />
                    <a href="https://vk.com/fastmparts" className={styles.social}>fastmotoparts</a>
                </div>
                <div className={styles.omfg}>
                    <img src={yt} alt="" />
                    <a href="#" className={styles.social}>fastmotoparts</a>
                </div>
                <div className={styles.omfg}>
                    <img src={ig} alt="" />
                    <a href="#" className={styles.social}>fastmotoparts</a>
                </div>
                
            </div>

            <div className={styles.phone}>
                <div className={styles.logo}>
                    <img src={logo} alt="" />
                </div>
                
                <div className={styles.info} ref={btnRef}>
                    <div className={styles.PhoneMedia}>
                        <div className={styles.PhoneMediaSocial}>
                            <a href="https://vk.com/fastmparts"><img src={vk} alt="" /></a>
                            <a className={styles.mini} href="#"><img src={ig} alt="" /></a>
                            <a className={styles.mini} href="https://t.me/fastmotoparts"><img src={tg} alt="" /></a>
                            <a href="#"><img src={yt} alt="" /></a>
                        </div>

                        <div className={styles.PhoneNumbers}>
                            <a href="tel:+375296759394">+375296759394</a>
                            <a href="tel:+375444651227">+375444651227</a>
                            <a href="tel:+79534423671">+79534423671</a>
                        </div>
                    </div>
                    <div className={styles.mail}>
                        <a href="mailto:fastmotopa@gmail.com">fastmotopa@gmail.com</a>
                    </div>
                </div>
            </div>

            <div className={styles.hidden}></div>
        </div>
    )

}

export default MyHeader;