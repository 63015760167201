import React from "react";
import styles from './about.module.css'
import odin from "../../img/01.svg"
import dva from "../../img/02.svg"
import tri from "../../img/03.svg"
import chetire from "../../img/04.svg"
import pyat from "../../img/05.svg"
import shest from "../../img/06.svg"
import mb from "../../img/mb-about.svg"

const About = () => {


    return(
        <div className={styles.main}>
            <h4>О компании</h4>
            <h3>КАК МЫ РАБОТАЕМ</h3>


            <ul className={styles.aboutList}>
                <li><img src={odin}/>Оставьте заявку любым удобным для Вас способом - позвоните, заполните форму,
напишите на почту.</li>
                <li><img src={dva}/>В течении часа мы связываемся с вами, оговариваем нужные марку/модель/год, начинаем
подбирать варианты.</li>
                <li><img src={tri}/>Если машинокомплект есть у нас в наличии - вы сможете его получить в день обращения.</li>
                <li><img src={chetire}/>Если в Беларуси нет таких машинокомплектов, мы подбираем варианты с аукционов и
разборок Европы, предоставляем вам описания и фото.</li>
                <li><img src={pyat}/>После согласования с вами покупки машинокомплекта, мы за свой счет покупаем,
доставляем, растамаживаем и разбираем (возможна доставка в любую точку).</li>
                <li><img src={shest}/>Вы получаете машинокомплект, осматриваете и, после этого, оплачиваете. Вся процедура
занимает не более 30 дней.</li>
            </ul>
        
            <div className={styles.mb}><img src={mb}  /></div>
        </div>
    )
}

export default About;