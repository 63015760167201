import React, { useRef } from "react";
import styles from "./MainPage.module.css"
import bg from "../../img/bg.png"
import MyHeader from "../Header/MyHeader";
import geo from "../../img/image 10.svg"

const MainPage = () => {

    const ref = useRef(null)

    const handleClick = () => {
        
    }

    return(
        <div className={styles.main}>
            <MyHeader/>
            <div className={styles.mainArea}>
                <h1>МАШИНОКОМПЛЕКТЫ <br /> И АВТО</h1>
                <h2>из Англии, Европы и США</h2>

                <div className={styles.omfg}>
                    <img src={geo} alt="" />
                    <p>Полное сопровождения от расчета стоимости до передачи в руки</p>
                </div>
                <div className={styles.omfg}>
                    <img src={geo} alt="" />
                    <p>Возможность покупки автомобиля под ключ из Европы и Сша</p>
                </div>
                <div className={styles.omfg}>
                    <img src={geo} alt="" />
                    <p>Выгодные предложения для юридических лиц имеющих автосервисы или авторазборки</p>
                </div>

                <a href="#svyaz">
                <div className={styles.btn} onClick={handleClick}>
                    Оставить заявку
                </div>
                </a>
            </div>
        </div>
    )
}

export default MainPage;