import React, { useRef, useState } from "react";
import styles from "./MyForm.module.css"
import emailjs from '@emailjs/browser';
import Modal from "../UI/modal/Modal";


const MyForm = (props) => {
    const [modalActive, setModalActive] = useState(false)
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_nyz7ebq',
     'template_u3x5i8c',
      form.current, 
      'eQuzmL5BmXV6mR-x2')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    }

    return(
        <div className={styles.main}>
            
            <p>Оставьте заявку и мы подберём оптимальный вариант</p>

            <form ref={form} onSubmit={sendEmail} className={styles.CallArea}>
                <input type="text" name="user_name" className={styles.MyInput} placeholder="Ваше имя"/>
                <input type="text" name="user_email" className={styles.MyInput} placeholder="Номер телефона или почта"/>
                <input type="submit" value="Оставить заявку" className={styles.btn} onClick={() => setModalActive(true)}/>
            </form>

            <p className={styles.wtf}>Или свяжитесь самостоятельно по номерам:</p>
            <p className={styles.red}>+7 (953) 442-36-71 или +375 (44) 465-12-27</p>

            <Modal active={modalActive} setActive={setModalActive}/>
        </div>


    )

}

export default MyForm;