import React, { useRef, useState } from "react";
import styles from "./RazborkaPage.module.css"
import fullXray from "../../img/full-xray.svg"
import semiXray from "../../img/semi-xray.svg"
import miniXray from "../../img/mini-xray.svg"
import phoneXray from "../../img/xrayphone.svg"

const RazborkaPage = () => {

    const fullRef = useRef()
    const semiRef = useRef()
    const miniRef = useRef()
    const engineRef = useRef()

    const fullXrayRef = useRef()
    const semiXrayRef = useRef()
    const miniXrayRef = useRef()

    const phoneengineRef = useRef()
    const phonesemiRef = useRef()
    const phonefullRef = useRef()
    const phoneminiRef = useRef()
    
    const fullPhoneRef = useRef()
    const semiPhoneRef = useRef()
    const miniPhoneRef = useRef()
    

    
    let Full = () => {
        fullRef.current.style.background = '#D62828'
        fullRef.current.style.color = '#fff'

        semiRef.current.style.background = '#ECECEC'
        miniRef.current.style.background = '#ECECEC'
        engineRef.current.style.background = '#ECECEC'
        semiRef.current.style.color = "#000"
        miniRef.current.style.color = "#000"
        engineRef.current.style.color = "#000"

        fullXrayRef.current.style.display = "block"
        semiXrayRef.current.style.display = "none"
        miniXrayRef.current.style.display = "none"

    }

    let Semi = () => {
        semiRef.current.style.background = '#D62828'
        semiRef.current.style.color = '#fff'

        fullRef.current.style.background = '#ECECEC'
        miniRef.current.style.background = '#ECECEC'
        engineRef.current.style.background = '#ECECEC'
        fullRef.current.style.color = "#000"
        miniRef.current.style.color = "#000"
        engineRef.current.style.color = "#000"

        semiXrayRef.current.style.display = "block"
        fullXrayRef.current.style.display = "none"
        miniXrayRef.current.style.display = "none"

    }

    let Mini = () => {
        miniRef.current.style.background = '#D62828'
        miniRef.current.style.color = '#fff'

        semiRef.current.style.background = '#ECECEC'
        fullRef.current.style.background = '#ECECEC'
        engineRef.current.style.background = '#ECECEC'
        semiRef.current.style.color = "#000"
        fullRef.current.style.color = "#000"
        engineRef.current.style.color = "#000"

        semiXrayRef.current.style.display = "none"
        fullXrayRef.current.style.display = "none"
        miniXrayRef.current.style.display = "block"
    }

    let Engine = () => {
        engineRef.current.style.background = '#D62828'
        engineRef.current.style.color = '#fff'

        semiRef.current.style.background = '#ECECEC'
        miniRef.current.style.background = '#ECECEC'
        fullRef.current.style.background = '#ECECEC'
        semiRef.current.style.color = "#000"
        miniRef.current.style.color = "#000"
        fullRef.current.style.color = "#000"

        semiXrayRef.current.style.display = "none"
        fullXrayRef.current.style.display = "none"
        miniXrayRef.current.style.display = "none"
    }

    let FullPhone = () =>{
        phonefullRef.current.style.background = '#D62828'
        phonefullRef.current.style.color = '#fff'

        phonesemiRef.current.style.background = '#ECECEC'
        phoneminiRef.current.style.background = '#ECECEC'
        phoneengineRef.current.style.background = '#ECECEC'
        phonesemiRef.current.style.color = "#000"
        phoneminiRef.current.style.color = "#000"
        phoneengineRef.current.style.color = "#000"

        semiPhoneRef.current.style.display = "none"
        fullPhoneRef.current.style.display = "block"
        miniPhoneRef.current.style.display = "none"

    }
    let SemiPhone = () =>{
        phonesemiRef.current.style.background = '#D62828'
        phonesemiRef.current.style.color = '#fff'

        phonefullRef.current.style.background = '#ECECEC'
        phoneminiRef.current.style.background = '#ECECEC'
        phoneengineRef.current.style.background = '#ECECEC'
        phonefullRef.current.style.color = "#000"
        phoneminiRef.current.style.color = "#000"
        phoneengineRef.current.style.color = "#000"

        semiPhoneRef.current.style.display = "block"
        fullPhoneRef.current.style.display = "none"
        miniPhoneRef.current.style.display = "none"
    }
    let MiniPhone = () =>{
        phoneminiRef.current.style.background = '#D62828'
        phoneminiRef.current.style.color = '#fff'

        phonesemiRef.current.style.background = '#ECECEC'
        phonefullRef.current.style.background = '#ECECEC'
        phoneengineRef.current.style.background = '#ECECEC'
        phonesemiRef.current.style.color = "#000"
        phonefullRef.current.style.color = "#000"
        phoneengineRef.current.style.color = "#000"

        semiPhoneRef.current.style.display = "none"
        fullPhoneRef.current.style.display = "none"
        miniPhoneRef.current.style.display = "block"
    }
    let EnginePhone = () =>{
        phoneengineRef.current.style.background = '#D62828'
        phoneengineRef.current.style.color = '#fff'

        phonesemiRef.current.style.background = '#ECECEC'
        phoneminiRef.current.style.background = '#ECECEC'
        phonefullRef.current.style.background = '#ECECEC'
        phonesemiRef.current.style.color = "#000"
        phoneminiRef.current.style.color = "#000"
        phonefullRef.current.style.color = "#000"

        semiPhoneRef.current.style.display = "none"
        fullPhoneRef.current.style.display = "none"
        miniPhoneRef.current.style.display = "none"
    }

    
    
    return (
        <div className={styles.main}>
            <h4 className={styles.pkz}>СХЕМЫ РАЗБОРКИ</h4>

            <div className={styles.btnArea}>
                <div className={styles.btn} onClick={Full} ref={fullRef}>Полный Комплект</div>
                <div className={styles.btn} onClick={Semi} ref={semiRef}>Полукомплект</div>
                <div className={styles.btn} onClick={Mini} ref={miniRef}>Мини Комплект</div>
                <div className={styles.btn} onClick={Engine} ref={engineRef}>Моторокомплект</div>
            </div>
            
            

            <div>
                <div ref={semiXrayRef} className={styles.xray}><img src={semiXray} /></div>
                <div ref={miniXrayRef} className={styles.xray}><img src={miniXray} /></div>
                <div ref={fullXrayRef} className={styles.xray}><img src={fullXray} /></div>
            </div>

            <div className={styles.phoneXray}>
            <div className={styles.btnAreaPhone}>
                <div className={styles.btn} onClick={FullPhone} ref={phonefullRef}>Полный Комплект</div>
                <div className={styles.btn} onClick={SemiPhone} ref={phonesemiRef}>Полукомплект</div>
                <div className={styles.btn} onClick={MiniPhone} ref={phoneminiRef}>Мини Комплект</div>
                <div className={styles.btn} onClick={EnginePhone} ref={phoneengineRef}>Моторокомплект</div>
            </div>
                <img src={phoneXray} alt="" />

                <div className={styles.xrayList} ref={fullPhoneRef}>
                    <ul>
                        <li>Торпедо в сборе</li>
                        <li>Двигатель и КПП</li>
                        <li>Блок радиаторов</li>
                        <li>Передний бампер</li>
                        <li>Фары</li>
                        <li>Крылья</li>
                        <li>Двери в сборе</li>
                        <li>Задняя подвеска </li>
                        <li>Задний бампер</li>
                        <li>Крышка багажника</li>
                        <li>Фонари</li>
                    </ul>
                </div>

                <div className={styles.xrayList} ref={semiPhoneRef}>
                    <ul>
                        <li>Комплектующие торпедо</li>
                        <li>Двигатель и КПП</li>
                        <li>Блок радиаторов</li>
                        <li>Передний бампер</li>
                        <li>Фары</li>
                        <li>Крылья</li>
                        <li>Комплектующие дверей</li>
                        <li>Задняя подвеска </li>
                        <li>Задний бампер</li>
                        <li>Крышка багажника</li>
                        <li>Фонари</li>
                    </ul>
                </div>

                <div className={styles.xrayList} ref={miniPhoneRef}>
                    <ul>
                        <li>Щиток приборов</li>
                        <li>Двигатель и КПП</li>
                        <li>Фары</li>
                        <li>Задняя подвеска </li>
                    </ul>
                </div>

                <div>

                </div>

            </div>

            <p>Детали не входящие в машинокомплект обговариваются индивидуально*</p>
        
        </div>
    );
}

export default RazborkaPage;