import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import styles from "./Map.module.css"

const MyMap = () => {

    

    return(
        <div className={styles.map}>
            <YMaps>
                <div >
                
                <Map defaultState={{ 
                    center: [53.90, 27.56],
                     zoom: 10 
                     }}
                    width={"100vw"}>

                <Placemark geometry={[53.899727, 27.622602]} />

                </Map>
                </div>
            </YMaps>
        </div>
    )
}

export default MyMap;